export function CompanySection() {
  return (
    <div className={"company"}>
      <h2>About us</h2>
      <p>
        Trickyleaf was born in February 2023. We are a team of mathematicians
        able to transform ideas into concrete products, taking care of the whole
        development process, from analysis to deployment.
        We use cutting-edge development stacks, and disruptive technologies like AI & IoT.
      </p>
    </div>
  );
}
