import { ReactNode } from "react";

export function ByTheWake() {
  return (
    <Project
      img={process.env.PUBLIC_URL + "/projects-img/byTheWake.svg"}
      title={"ByTheWake"}
      description={
        <>
          <a href={"https://www.bythewake.com"}>ByTheWake</a> is the Wakeboard
          app by Trickyleaf. It allows wakeboarders to book, pay online, enroll
          in affiliated wake clubs, and, soon, to also track progress and
          statistics.
          <br />
          Its first version was released in May 2023, and will now expand and
          gain new features for riders and parks.
        </>
      }
    />
  );
}

export function Finance() {
  return (
    <Project
      img={process.env.PUBLIC_URL + "/projects-img/finance.png"}
      title={"DealBase"}
      description={
        <>
          <a href={"https://www.dealbase.co/"}>DealBase</a> is an Excel plug-in
          that optimizes and standardizes the main financial analyses of
          software company KPIs (Customer Sheet analyses, cohort retention
          analyses, TTM retention). It was released, in a beta version, in
          November 2023, and is being offered to the main international funds.
        </>
      }
    />
  );
}

export function CoffeeApp() {
  return (
    <Project
      img={process.env.PUBLIC_URL + "/projects-img/coffeeApp.png"}
      title={"Administration and logistics"}
      description={
        <>
          A tablet app, designed to assist the sales division of a company to
          handle the whole selling workflow, from warehouse operations to
          invoicing, keeping data in sync with the company's accounting
          management software. The app allows to handle all documents connected
          to the shipment and sale process, even when offline, and to print them
          using portable Zebra® printers.
        </>
      }
    />
  );
}

export interface ProjectProps {
  img: string;
  title: string;
  description: ReactNode;
}

export function Project(props: ProjectProps) {
  return (
    <div className="product-container">
      <img src={props.img} className="product-img" alt="Trickyleaf logo" />
      <div className="product-description">
        <h3>{props.title}</h3>
        <p className="product-text">{props.description}</p>
      </div>
    </div>
  );
}

export function ProjectsSection() {
  return (
    <div className={"projects"} id={"portfolio"}>
      <h2>Portfolio</h2>
      <div className={"projects-container"}>
        <Finance />
        <ByTheWake />
        <CoffeeApp />
      </div>
    </div>
  );
}
