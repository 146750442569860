import React from "react";
import linkedinSvg from "../linkedin.svg";

export function Nico() {
  return (
    <TeamMember
      img={"nico"}
      name={"Nicola Daprà"}
      cv={`
        After graduating in Mathematics, he started his career in strategic consulting,
        before focusing on artificial intelligence products,
        first in consultancy, then in industrial process control.
       `}
      linkedInLink={"https://www.linkedin.com/in/nicola-dapr%C3%A0-2757651b5/"}
    />
  );
}

export function Leo() {
  return (
    <TeamMember
      img={"leo"}
      name={"Leonardo Fiore"}
      cv={`
          Prospect PhD in number theory.
          During his university studies, he created a number of IT solutions in different sectors while working as a freelancer.
          Some different sectors he has worked in include OMR technologies for automated exam grading, catalog management & budgeting software, IT solutions for the theatrical industry.
       `}
      linkedInLink={"https://www.linkedin.com/in/leonardo-fiore-b45840285/"}
    />
  );
}

export function Marta() {
  return (
    <TeamMember
      name={"Marta Cortesi"}
      cv={`
          After graduating from Accademia di Brera, she obtained a master degree in digital graphics and animation.
          She is in charge of graphic design choices for our products.
       `}
      linkedInLink={"https://www.linkedin.com/in/marta-cortesi/"}
      img={"marta"}
    />
  );
}

export function Cappo() {
  return (
    <TeamMember
      img={"cappo"}
      name={"Andrea Cappelletti"}
      cv={`
        PhD in mathematical logics, and maths teacher. He takes care of front-end development and communication for Trickyleaf.       `}
    />
  );
}

export interface TeamMemberProps {
  name: string;
  cv: string;
  linkedInLink?: string;
  img: string;
}

export function TeamMember(props: TeamMemberProps) {
  return (
    <div className="team-member">
      <div className="team-image-container">
        <img
          src={process.env.PUBLIC_URL + "/team-img/" + props.img + ".png"}
          className="team-image"
          alt={"Team member"}
        />
      </div>
      <div className="team-details">
        <div className="team-name">
          <div className="team-name-main">{props.name}</div>
          {!!props.linkedInLink && (
            <div className="team-links">
              <a href={props.linkedInLink} target="_blank" rel="noreferrer">
                <img src={linkedinSvg} alt="LinkedIn" />
              </a>
            </div>
          )}
        </div>
        <p className="team-cv">{props.cv}</p>
      </div>
    </div>
  );
}

export function TeamSection() {
  return (
    <div className={"team"} id={"team"}>
      <h2>Team</h2>
      <div className={"team-members-container"}>
        <Nico />
        <Leo />
        <Marta />
        <Cappo />
      </div>
    </div>
  );
}
