import { Canvas, useFrame } from "@react-three/fiber";
import React, { useCallback, useMemo, useRef } from "react";
import { PointMaterial } from "@react-three/drei";
function Points() {
  const bufferRef = useRef<any>();

  let t = 0;
  let f = 0.002;
  let f2 = 0.003;
  let a = 2;
  const c = 0.5;
  const graph = useCallback(
    (x: number, z: number) => {
      return (Math.sin(f * (x*z + c*t))+Math.sin(f2 * (x**2+z**2 + c*t))) * a;
    },
    [t, f, a, f2],
  );

  const count = 50;
  const sep = 3;
  let positions = useMemo(() => {
    let positions = [];

    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);
        let y = graph(x, z);
        positions.push(x, y, z);
      }
    }

    return new Float32Array(positions);
  }, [count, sep, graph]);

  useFrame(() => {
    t += 15;

    const positions = bufferRef.current.array;

    let i = 0;
    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);

        positions[i + 1] = graph(x, z);
        i += 3;
      }
    }

    bufferRef.current.needsUpdate = true;
  });

  return (
    <points>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          ref={bufferRef}
          attach="attributes-position"
          array={positions}
          count={positions.length / 3}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-color"
          count={positions.length / 3}
          array={positions.map(() => 0xffffffaa)}
          itemSize={3}
        />
      </bufferGeometry>
      <PointMaterial
        size={0.5}
        vertexColors
        color={0xffffff}
        attach="material"
        opacity={1}
        transparent
      />
    </points>
  );
}

export default function AnimationCanvas() {
  return (
    <Canvas camera={{ position: [50, 10, 0], fov: 75 }}>
      <Points />
    </Canvas>
  );
}
