import React from "react";
import logo from "./Logo_Trickyleaf.svg";
import "./App.scss";
import { TeamSection } from "./page-parts/Team";
import { ProjectsSection } from "./page-parts/Projects";
import Particles from "./Particles";
import { ContactsSection } from "./page-parts/Contacts";
import { FooterSection } from "./page-parts/Footer";
import { CompanySection } from "./page-parts/Company";
function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="scrollable">
        <FancyBackground />
        <div className={"container"}>
          <CompanySection />
          <ProjectsSection />
          <TeamSection />
          <ContactsSection />
        </div>
        <div className={"footer-background"}>
          <div className={"container"}>
            <FooterSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export function FancyBackground() {
  return (
    <div className={"cover-page"}>
      <div className={"text-container"}>
        <h1>Let your business turn over a new leaf</h1>
      </div>
      <div id="background">
        <div id={"particles-container"}>
          <Particles />
        </div>
        <div id={"particles-overlay-gradient"}></div>
      </div>
    </div>
  );
}

export function NavBar() {
  return (
    <nav>
      <div className={"nav-header"}>
        <img className={"tl-logo"} src={logo} alt="Trickyleaf logo" />
        <p className={"company-name"}>Trickyleaf</p>
      </div>
      <div className={"nav-menu"}>
        <a href="#portfolio">Portfolio</a>
        <a href="#team">Team</a>
        <a href="#contacts">Contacts</a>
      </div>
    </nav>
  );
}

export default App;
