export function FooterSection() {
  return (
    <div className={"footer"}>
      <p className={"footer-text"}>
        Trickyleaf S.r.l. - Viale delle Legioni Romane 43, 20147 Milan - P.IVA
        and C.F. 12830250960 -{" "}
        <span>
          E-mail{" "}
          <a href="mailto:company@trickyleaf.com">company@trickyleaf.com</a>
        </span>
        &nbsp;-&nbsp;
        <span>
          PEC <a href="mailto:trickyleaf@pec.it">trickyleaf@pec.it</a>
        </span>
        &nbsp;-&nbsp;
        <span>
          Website <a href="https://www.trickyleaf.com">www.trickyleaf.com</a>
        </span>
        <br />
        Copyright © 2023 &nbsp;-&nbsp; All rights reserved
      </p>
    </div>
  );
}
