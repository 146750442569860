export function ContactsSection() {
  return (
    <div id={"contacts"}>
      <h2>Contacts</h2>
      <p style={{ textAlign: "center" }}>
        Our offices are in Milan, Viale Legioni Romane 43.
        <br />
        You can contact us at{" "}
        <a href="mailto:company@trickyleaf.com">company@trickyleaf.com</a>
      </p>
    </div>
  );
}
